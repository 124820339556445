import {combineDataProviders} from 'react-admin';
import dataProvider from './dataProvider'

const dataProviders = [
    {
        dataProvider: dataProvider(process.env.REACT_APP_POSTGREST_VEHICLES_URL),
        resources: [
            'vehicles.vehicle',
            'vehicles.subconcessionaire',
            'vehicles.operators_vw',
            'vehicles.constants',
            'vehicles.vehicle_types',
        ]
    },
    {
        dataProvider: dataProvider(process.env.REACT_APP_POSTGREST_ADMINISTRATION_URL),
        resources: [
            'administration.users',
            'administration.user_contexts',
            'administration.user_delegations',
            'administration.contexts'
        ]
    },
    {
        dataProvider: dataProvider(process.env.REACT_APP_POSTGREST_OPERATORS_PORTAL_URL),
        resources: [
            'operators_portal.line_events_vw',
            'operators_portal.manual_data_uploads',
            'operators_portal.operators_vw',
            'operators_portal.constants',
            'operators_portal.attachments',
            'operators_portal.strike',
            'operators_portal.reports_vw',
            'operators_portal.report_types',
            'operators_portal.outage_intervals',
            'operators_portal.extra_bus_information_vw',
            'operators_portal.manual_passenger_counting',
            'operators_portal.report_attachments',
            'operators_portal.segment_changes_log',
            'operators_portal.segment_changes',
            'operators_portal.segment_changes_filter_operational_context_vw',
            'operators_portal.itcs_efa',
            process.env.REACT_APP_BUILD_FLAVOR == 'trial'? 'operators_portal.lots_vw': null,
        ]
    },
    {
        dataProvider: dataProvider(process.env.REACT_APP_POSTGREST_DWH_OPERATORS_PORTAL_URL),
        resources: [
            'operators_portal.rt_events_vw',
            'operators_portal.extra_bus_information_vw',
            'operators_portal.service_link_mvw',
            'operators_portal.sasa_journey_codes_vw',
            'operators_portal.mandants_vw',
            process.env.REACT_APP_BUILD_FLAVOR != 'trial'? 'operators_portal.lots_vw': null,
        ]
    },
];

export default combineDataProviders((resource) => {
    const dataProviderMapping = dataProviders.find(dp => dp.resources.includes(resource));
    return dataProviderMapping.dataProvider;
})
